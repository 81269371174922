import { useQuery } from '@tanstack/react-query';
import getJobList from '../api/getJobList';

function useJobList() {
  return useQuery(['jobs'], () => getJobList(), {
    keepPreviousData: true,
    staleTime: Infinity,
  });
}

export default useJobList;
