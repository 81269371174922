import axios from 'axios';
import { xmltojson } from '../utils/xml';
import { formatDetailResponse } from '../utils/formatRepsonse';

const getJob = async (jobId) => {
  const { data } = await axios.get(`${jobId}.xml`);

  const jsonRepsponse = xmltojson(data, { mergeAttrs: true }).then((data) =>
    formatDetailResponse(data),
  );

  return jsonRepsponse;
};

export default getJob;
