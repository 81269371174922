import { useContext } from 'react';
import UrlContext from '../../common/context/urlContext';
import Link from '../../common/components/Link/Link';
import { DETAILURL } from '../../constants';
import Card, { CardBody } from '../Card/Card';
import DetailsList from '../JobsDetails/DetailsList';
import CardHeader from '../Card/CardHeader';

const JobSchool = ({ job, school }) => {
  if (!job.location && !school.name) return null;
  return (
    <div className="job-school">
      {job.location && <span className="school-city">{job.location} | </span>}
      {school.name && <span className="school-name">{school.name}</span>}
    </div>
  );
};

const JobDetails = ({ job }) => {
  if (!job.categories) return null;

  const jobDetailsMapper = {
    position_type: 'fa-tag c-theme',
    extent: 'fa-clock c-theme',
  };

  return <DetailsList details={job.categories} mapper={jobDetailsMapper} />;
};

function JobCard({ job }) {
  const { baseUrl } = useContext(UrlContext);
  const detailUrl = `${baseUrl}${DETAILURL}${job.id}`;

  return (
    <Card className="job-card post-card">
      <CardHeader
        img={job.school.logourl}
        placeholder="fa-briefcase"
        alt={job.job_details.title}
      />
      <CardBody>
        <JobSchool job={job.job_details} school={job.school} />
        <h2 className="post-card-title mt-2">
          <Link to={detailUrl} className="text-reset" inRouter={true}>
            {job.job_details.title}
          </Link>
        </h2>
        {job.job_details.titleheading && (
          <h3 className="card-subtitle">{job.job_details.titleheading}</h3>
        )}
        {job.job_details.engagement && (
          <p><span className="c-theme">Startdatum</span>: {job.job_details.engagement}</p>
        )}
        <JobDetails job={job.job_details} />
        <Link
          to={detailUrl}
          className="btn btn-default c-theme btn-detail"
          inRouter={true}
        >
          Bekijk vacature
        </Link>
      </CardBody>
    </Card>
  );
}

export default JobCard;
