import { isEmpty } from 'lodash';

function DetailsList({ details, mapper, className, title = false }) {
  return (
    <ul className={`details-list list-unstyled mb-0 ${className}`}>
      {Object.keys(mapper).map((detailKey, index) => {
        const detail = details[detailKey];
        if (!isEmpty(detail)) {
          const detailValue = detail['name'];
          return (
            <li className="list-item detail-item" key={index}>
              <i className={`fa ${mapper[detailKey]} mr-2`}></i>
              {typeof detailValue === 'object'
                ? detailValue.join(', ')
                : detailValue}
            </li>
          );
        } else {
          return null;
        }
      })}
    </ul>
  );
}

export default DetailsList;
