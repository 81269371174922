import { useContext } from 'react';
import { Link } from 'react-router-dom';
import UrlContext from '../../context/urlContext.js';

const BackLink = () => {
  const { baseUrl } = useContext(UrlContext);
  return (
    <Link to={baseUrl} className="btn-back">
      <i className="fa fa-arrow-left" aria-hidden="true"></i> Terug naar overzicht
    </Link>
  );
};

export default BackLink;
