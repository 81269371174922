import { Link as RouteLink } from 'react-router-dom';
import classNames from 'classnames';

/*
Renders either an a tag or a React-router Link component
Mostly used in the card components as they are renderd in routes and in django pages
if a card component is rendered in a django page the link needs to point to the app page
so the link must render an a tag, set inRouter to false
*/

const Link = ({ to, children, className = '', inRouter = false }) => {
  const classes = classNames(className);
  const Tag = inRouter ? RouteLink : 'a';
  const toAttribute = {};
  const toAttributeKey = inRouter ? 'to' : 'href';
  toAttribute[toAttributeKey] = to;

  return (
    <Tag className={classes} {...toAttribute}>
      {children}
    </Tag>
  );
};

export default Link;
