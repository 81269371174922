import Loading from '../common/components/Loading/Loading';
import Container from '../common/components/Layouts/Container';
import useJobList from '../hooks/useJobList';
import JobCard from '../components/JobCard/JobCard';
import './listview.scss';
import Sidebar from '../components/Sidebar/Sidebar';

function ListView({ introText, sidebarContent }) {
  const { data, isInitialLoading, isSuccess, isError } = useJobList();

  return (
    <Container className="jobs-list-wrapper">
      <div className="row">
        {introText && (
          <div className="col-12">
            <div
              className="jobs-intro-text"
              dangerouslySetInnerHTML={{ __html: introText }}
            />
          </div>
        )}
        {isInitialLoading && <Loading fullscreen />}
        {isError && (
          <div className="alert alert-danger">Kon lijst niet ophalen</div>
        )}
        {sidebarContent && (
          <Sidebar>
            <div dangerouslySetInnerHTML={{ __html: sidebarContent }} />
          </Sidebar>
        )}
        {isSuccess && (
          <div className={`col-${sidebarContent ? '8' : '12'}`}>
            <div className="jobs-list-wrapper easycruit-list">
              <div className="jobs-list">
                {data.vacancylist &&
                  data.vacancylist.vacancy.map((job, index) => (
                    <JobCard job={job} key={index} />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

export default ListView;
