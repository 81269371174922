import axios from 'axios';
import { xmltojson } from '../utils/xml';
import { formatResponse } from '../utils/formatRepsonse';

const getJobList = async () => {
  const { data } = await axios.get(`list.xml`);

  const jsonRepsponse = xmltojson(data).then((data) => formatResponse(data));

  return jsonRepsponse;
};

export default getJobList;
