import { isArray, isObject } from 'lodash';
import { isBefore, isAfter, parse } from 'date-fns';

const createCategoriesObj = (categories) => {
  if (!categories.item) return null;

  if (isArray(categories.item)) {
    return categories.item.reduce((obj, category) => {
      obj[category.type.replaceAll('-', '_')] = {
        id: category.id,
        name: category._,
      };

      return obj;
    }, {});
  }

  if (isObject(categories.item) && !isArray(categories.item)) {
    const categoryObj = {};
    categoryObj[categories.item.type.replaceAll('-', '_')] = {
      id: categories.item.id,
      name: categories.item._,
    };

    return categoryObj;
  }
};

const createVacancyObj = (vacancy) => {
  const vacancyObj = {};
  vacancyObj['id'] = vacancy.id;
  vacancyObj['date_end'] = vacancy.date_end;
  vacancyObj['date_start'] = vacancy.date_start;
  vacancyObj['school'] = { ...vacancy.departments.department };
  vacancyObj['school']['address'] =
    vacancy.departments.department.address?.reduce((obj, item) => {
      obj[item.type.replaceAll('-', '_')] = {
        street: item.street,
        area: item.area,
        areacode: item.areacode,
      };

      return obj;
    }, {});
  vacancyObj['job_details'] = { ...vacancy.versions.version };
  vacancyObj['job_details']['categories'] = createCategoriesObj(
    vacancy.versions.version.categories,
  );

  return vacancyObj;
};

const sortOnStartDate = (a, b) => {
  const { date_start: firstDate } = a;
  const { date_start: secondDate } = b;
  const parsedFirstDate = parse(firstDate, 'yyyy-MM-dd', new Date());
  const parsedSecondDate = parse(secondDate, 'yyyy-MM-dd', new Date());
  if (isBefore(parsedFirstDate, parsedSecondDate)) return 1;
  if (isAfter(parsedFirstDate, parsedSecondDate)) return -1;
  return 0;
};

export function formatResponse(data) {
  const rootKey = Object.keys(data);
  const unformattedVacancies = data[rootKey]['vacancy'];
  let formattedVacancies;

  if (isArray(unformattedVacancies)) {
    formattedVacancies = unformattedVacancies.map(createVacancyObj);
  } else {
    formattedVacancies = [unformattedVacancies].map(createVacancyObj);
  }

  formattedVacancies.sort(sortOnStartDate);

  return { [rootKey]: { ...data[rootKey], vacancy: formattedVacancies } };
}

export function formatDetailResponse(data) {
  const vacancyObj = data[Object.keys(data)];
  return createVacancyObj(vacancyObj);
}
