import { isEmpty, trim } from 'lodash';
import Sidebar from './Sidebar';
import Card, { CardBody, CardTitle } from '../Card/Card';

const JobDetails = ({ job }) => {
  if (!job.categories) return null;
  const details = job.categories;
  const jobDetailsMapper = {
    job_level: 'fa-graduation-cap',
    position_type: 'fa-tag',
    duration: 'fa-briefcase',
    extent: 'fa-clock',
    operating_time: 'fa-calendar-day',
  };

  return (
    <Card>
      <CardBody>
        <CardTitle>Vacature details</CardTitle>
        <ul className="details-list list-unstyled mb-0">
          {Object.keys(jobDetailsMapper).map((detailKey, index) => {
            const detail = details[detailKey];
            if (!isEmpty(detail)) {
              const detailValue = detail['name'];
              return (
                <li className="list-item detail-item" key={index}>
                  <i className={`fa ${jobDetailsMapper[detailKey]} mr-2`}></i>
                  {typeof detailValue === 'object'
                    ? detailValue.join(', ')
                    : detailValue}
                </li>
              );
            } else {
              return null;
            }
          })}
          {job.engagement && (
            <li className="list-item detail-item">
              <span className="c-theme">Startdatum</span>: {job.engagement}
            </li>
          )}
        </ul>
      </CardBody>
    </Card>
  );
};

const SchoolDetails = ({ school }) => {
  if (!school) return null;
  const address = `${school.address.office.street}
  ${school.address.office.area} ${school.address.office.areacode}`;

  return (
    <Card className="my-4">
      <CardBody>
        <CardTitle>Contact</CardTitle>
        <ul className="details-list list-unstyled mb-0">
          <li className="list-item detail-item">
            <i className="fa fa-university mr-2"></i>
            {school.name}
          </li>
          {trim(address).length > 0 && (
            <li className="list-item detail-item">
              <i className="fa fa-map-pin mr-2"></i>
              {address}
            </li>
          )}
          {school.contactpersons.contactperson && (
            <li className="list-item detail-item">
              <i className="fa fa-envelope mr-2"></i>
              {school.contactpersons.contactperson.commonname}
            </li>
          )}

          <li className="list-item detail-item">
            <i className="fa fa-globe mr-2"></i>
            <a
              href={school.homepageurl}
              target="_blank"
              rel="noreferrer"
              className="text-reset"
            >
              Bezoek schoolsite
            </a>
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

function DetailSidebar({ job, school }) {
  return (
    <Sidebar className="job-detail-sidebar">
      <JobDetails job={job} />
      <SchoolDetails school={school} />
      <a
        href={school.applicationurl}
        target="_blank"
        className="btn btn-block btn-lg b-theme c-white"
        rel="noreferrer"
      >
        Solliciteren
      </a>
    </Sidebar>
  );
}

export default DetailSidebar;
