import { useParams } from 'react-router';
import Container from '../common/components/Layouts/Container';
import Loading from '../common/components/Loading/Loading';
import BackLink from '../common/components/Link/BackLink';
import useJob from '../hooks/useJob';
import DetailSidebar from '../components/Sidebar/DetailSidebar';

function Detailview() {
  let { jobId } = useParams();
  const { data, isInitialLoading, isError, isSuccess } = useJob(jobId);

  if (isInitialLoading) return <Loading fullscreen />;

  if (isError)
    return <div className="alert alert-danger">Kon item niet ophalen</div>;

  const { job_details, school } = data;

  if (isSuccess) {
    return (
      <Container className="job-detail">
        <div className="row">
          <div className="col-md-12">
            <div className="job-detail-header">
              <nav aria-label="breadcrumb" className="vacancy-breadcrumb">
                <BackLink />
              </nav>
              <h2 className="subtitle">{job_details.titleheading}</h2>
              <h1 className="article-title job-title">{job_details.title}</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: job_details.description }}
            />
            {school && (
              <div className="school-section">
                <h2 className="school-name">{school.name}</h2>
                <div
                  className="school-about"
                  dangerouslySetInnerHTML={{
                    __html: school.versions.version.about,
                  }}
                />
              </div>
            )}
          </div>
          <DetailSidebar job={job_details} school={school} />
        </div>
      </Container>
    );
  }
}

export default Detailview;
