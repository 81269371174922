import './loading.scss';

const Loading = ({ text, fullscreen = false }) => {
  return (
    <div className={`loading-wrapper ${fullscreen ? 'loading-fullscreen' : ''}`}>
      <div className="spinner-grow" role="status">
        <span className="sr-only">{text}</span>
      </div>
      <span className="loading-text">{text}</span>
    </div>
  );
};

export default Loading;
