import { useQuery } from '@tanstack/react-query';
import getJob from '../api/getJob';

function useJob(jobId) {
  return useQuery(['jobs', {id: jobId}], () => getJob(jobId), {
    keepPreviousData: true,
    staleTime: Infinity,
  });
}

export default useJob;
