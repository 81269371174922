import classNames from 'classnames';

const Container = (props) => {
  const { className, children } = props;
  const classes = classNames('container', className);

  return <div className={classes}>{children}</div>;
};

export default Container;
