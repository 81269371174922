export function CardBody({ className, children }) {
  return (
    <div className={`card-body ${className ? className : ''}`}>{children}</div>
  );
}

export function CardTitle({ className, children }) {
  return (
    <h3 className={`card-title ${className ? className : ''}`}>{children}</h3>
  );
}

function Card({ className, children }) {
  return <div className={`card ${className ? className : ''}`}>{children}</div>;
}

export default Card;
