import './cardHeader.scss';

function CardHeader({ img, alt, placeholder, imgSize = 'contain' }) {
  if (img === null || img === undefined) {
    return (
      <div className="post-card-header post-card-header__no-img mt-0">
        <i className={`fas ${placeholder}`}></i>
      </div>
    );
  }

  return (
    <div className="post-card-header mt-0">
      <img
        src={img}
        alt={alt}
        className={`post-card-header-image ${imgSize}`}
      />
    </div>
  );
}

export default CardHeader;
