import xml2js from 'xml2js';

export async function xmltojson(data, options = {}) {
  const defaultXmlOptions = {
    mergeAttrs: true,
    trim: true,
    explicitArray: false,
    normalizeTags: true,
  };

  const xmlOptions = { ...defaultXmlOptions, ...options };

  const result = await xml2js.parseStringPromise(data, xmlOptions);
  return result;
}
