import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Router from './root/Router';
import {createRoot} from "react-dom/client";
import UrlContext from './common/context/urlContext';

const JobListElement = document.getElementById('root');
const { url, baseurl } = JobListElement.dataset;
const introElement = document.getElementById('jobs-intro');
const sidebarElement = document.getElementById('jobs-sidebar');
let introText;
let sidebarContent;

if (introElement) {
  introText = introElement.innerHTML;
}
if (sidebarElement) {
  sidebarContent = sidebarElement.innerHTML;
}

// sets the baseurl for the whole app
axios.defaults.baseURL = url;
const contextValue = {
  externalUrl: url,
  baseUrl: baseurl ? baseurl : '/',
};

const jobListRoot = createRoot(JobListElement)
jobListRoot.render(
    <UrlContext.Provider value={contextValue}>
      <Router introText={introText} sidebarContent={sidebarContent} />
    </UrlContext.Provider>
);
