import { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import QueryClientWrapper from '../common/QueryClient';
import UrlContext from '../common/context/urlContext';
import { DETAILURI } from '../constants';
import ListView from '../pages/ListView';
import DetailView from '../pages/DetailView';

function Root({ introText, sidebarContent }) {
  const { baseUrl } = useContext(UrlContext);

  return (
    <QueryClientWrapper>
      <Router>
        <Switch>
          <Route path={baseUrl} exact>
            <ListView introText={introText} sidebarContent={sidebarContent} />
          </Route>
          <Route path={`${baseUrl}${DETAILURI}`} exact>
            <DetailView />
          </Route>
        </Switch>
      </Router>
    </QueryClientWrapper>
  );
}

export default Root;
